<template>
  <b-card-actions :title="$t('Filter')" action-collapse>
    <b-row class="filter">
      <!-- Payment Type -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-m ipadd-0 mb-2">
        <label>{{ $t("Payment type") }}</label>
        <v-select
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.type"
          :options="typeOptions"
          :searchable="false"
          class="w-100"
          :reduce="(val) => val.value"
        />
        <!-- @input="(val) => $emit('update:typeFilter', val)" -->
      </b-col>
      <!-- Transaction ID -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <label>{{ $t("Transaction id") }}</label>
        <b-form-input
          v-model="filter.reference"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Username -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <label>{{ $t("Username") }}</label>
        <b-form-input
          v-model="filter.username"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Status -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-m ipadd-0 mb-2">
        <label>{{ $t("Status") }}</label>
        <v-select
          :searchable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.status"
          :options="statusOptions"
          :filterable="false"
          :reduce="(val) => val.value"
          class="w-100"
        />
        <!-- :reduce="(val) => val.value" -->
        <!-- @input="(val) => $emit('update:statusFilter', val)" -->
      </b-col>
      <!-- Currency -->
      <!-- <b-col cols="12" md="4" lg="3" sm="6" class="mb-m ipadd-0 mb-2">
        <label>{{ $t("Currency") }}</label>
        <v-select
          :searchable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.currency"
          :options="currencyOptions"
          class="w-100"
          :reduce="(val) => val.id"
          label="value"
        @input="(val) => $emit('update:currencyFilter', val)"
        />
      </b-col> -->
      <!-- From Date Fiter -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <b-form-group :label="$t('From date')" label-for="fromDate">
          <flat-pickr
            id="fromDate"
            v-model="filter.fromdate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-form-group>
      </b-col>
      <!-- To Date Filter -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <b-form-group :label="$t('To date')" label-for="toDate">
          <flat-pickr
            id="toDate"
            v-model="filter.todate"
            class="form-control"
            placeholder="YYYY-MM-DD HH:II:SS"
            :config="flatpickrConfig"
          />
        </b-form-group>
      </b-col>
      <!-- Bank -->
      <!-- <b-col cols="12" md="4" lg="3" sm="6" class="mb-m ipadd-0 mb-2">
        <label>{{ $t("Bank") }}</label>
        <v-select
          :searchable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.bank"
          :options="bankOptions"
          class="w-100"
          :reduce="(val) => val.code"
          label="bank_name"
        />
      </b-col> -->
      <!-- @input="(val) => $emit('update:bankFilter', val)" -->
      <!-- Bank Account -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <label>{{ $t("Bank account name") }}</label>
        <b-form-input
          v-model="filter.bank_account"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col>
      <!-- Member Account No -->
      <!-- <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <label>{{ $t("Account number") }}</label>
        <b-form-input
          v-model="filter.member_account_no"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col> -->
      <!-- Tags -->
      <b-col cols="12" md="4" lg="3" sm="6" class="mb-m ipadd-0 mb-2">
        <label>{{ $t("Tags") }}</label>
        <v-select
          :searchable="false"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          v-model="filter.tags"
          :options="tagsOptions"
          class="w-100"
          :reduce="(val) => val.id"
          label="name"
        />
      </b-col>
      <!-- @input="(val) => $emit('update:tagsFilter', val)" -->
      <!-- Reference No -->
      <!-- <b-col cols="12" md="4" lg="3" sm="6" class="mb-2 ipad">
        <label>{{ $t("Member bank ref no") }}</label>
        <b-form-input
          v-model="filter.referenceNo"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          class="w-100"
        />
      </b-col> -->
      <!-- Action -->
      <b-col cols="12" class="d-flex align-items-end justify-content-end">
        <b-button variant="outline-primary" @click="resetFilter()">
          <feather-icon icon="RefreshCwIcon" /> {{ $t("Reset") }}
        </b-button>
        <b-button variant="primary" class="ml-2" @click="searchFilter()">
          <feather-icon icon="SearchIcon" /> {{ $t("Search") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BFormDatepicker,
  BInputGroup,
  BInputGroupAppend,
  BCardText,
  BFormGroup,
  BFormInput,
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    BCardText,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BButton,
    vSelect,
    flatPickr,
  },
  props: {
    typeFilter: {
      type: Number,
      default: null,
    },
    referenceFilter: {
      type: [String, null],
      default: null,
    },
    usernameFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    currencyFilter: {
      type: [Number, null],
      default: null,
    },
    fromdateFilter: {
      type: [String, null],
      default: "",
    },
    todateFilter: {
      type: [String, null],
      default: "",
    },
    bankFilter: {
      type: [String, null],
      default: null,
    },
    bankAccountFilter: {
      type: [String, null],
      default: null,
    },
    accountNumberFilter: {
      type: [String, null],
      default: null,
    },
    tagsFilter: {
      type: [Number, null],
      default: null,
    },
    referenceNoFilter: {
      type: [String, null],
      default: null,
    },
    typeOptions: {
      type: Array,
      default: null,
    },
    statusOptions: {
      type: Array,
      default: null,
    },
    bankOptions: {
      type: Array,
      default: null,
    },
    tagsOptions: {
      type: Array,
      default: null,
    },
    // currencyOptions: {
    //   type: Array,
    //   default: null,
    // },
  },
  data() {
    return {
      filter: {
        type: this.typeFilter,
        reference: this.referenceFilter,
        username: this.usernameFilter,
        status: this.statusFilter,
        currency: this.currencyFilter,
        fromdate: this.fromdateFilter,
        todate: this.todateFilter,
        bank: this.bankFilter,
        bank_account: this.bankAccountFilter,
        member_account_no: this.accountNumberFilter,
        tags: this.tagsFilter,
        referenceNo: this.referenceNoFilter,
      },
      flatpickrConfig: {
        dateFormat: "Y-m-d H:i:S",
        enableTime: true,
        time_24hr: true,
      },
    };
  },
  methods: {
    changeFromDate() {
      this.$emit("update:fromdateFilter", this.filter.fromdate);
    },
    changeToDate() {
      this.$emit("update:todateFilter", this.filter.todate);
    },
    resetFilter() {
      this.filter = {
        type: null,
        reference: null,
        username: null,
        status: null,
        currency: null,
        fromdate: "",
        todate: "",
        bank: null,
        bank_account: null,
        member_account_no: null,
        tags: null,
        referenceNo: null,
      };
      this.$emit("update:typeFilter", null);
      this.$emit("update:referenceFilter", null);
      this.$emit("update:usernameFilter", null);
      this.$emit("update:statusFilter", null);
      this.$emit("update:currencyFilter", null);
      this.$emit("update:fromdateFilter", "");
      this.$emit("update:todateFilter", "");
      this.$emit("update:bankFilter", null);
      this.$emit("update:bankAccountFilter", null);
      this.$emit("update:accountNumberFilter", null);
      this.$emit("update:tagsFilter", null);
      this.$emit("update:referenceNoFilter", null);
      this.$emit("refetch-data");
    },
    searchFilter() {
      this.$emit("update:typeFilter", this.filter.type);
      this.$emit("update:referenceFilter", this.filter.reference);
      this.$emit("update:usernameFilter", this.filter.username);
      this.$emit("update:statusFilter", this.filter.status);
      this.$emit("update:currencyFilter", this.filter.currency);
      this.$emit("update:fromdateFilter", this.filter.fromdate);
      this.$emit("update:todateFilter", this.filter.todate);
      this.$emit("update:bankFilter", this.filter.bank);
      this.$emit("update:bankAccountFilter", this.filter.bank_account);
      this.$emit("update:accountNumberFilter", this.filter.member_account_no);
      this.$emit("update:tagsFilter", this.filter.tags);
      this.$emit("update:referenceNoFilter", this.filter.referenceNo);
      this.$emit("refetch-data");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
