<template>
  <b-card no-body class="card-statistics">
    <b-overlay :show="show" variant="transparent" no-wrap />
    <b-card-header>
      <b-card-title>
        {{ $t("Statistics") }}
        <feather-icon icon="BarChart2Icon" size="16" />
      </b-card-title>
      <b-card-text class="mr-25 mb-0 cursor-pointer">
        <feather-icon icon="ExternalLinkIcon" size="18" />
      </b-card-text>
    </b-card-header>
    <!-- {{ dataAnalytic }} -->
    <b-card-body class="statistics-body">
      <b-row>
        <div v-for="item in dataAnalytic" :key="item.txn_status_id">
          <div style="display: flex; padding: 8px 32px">
            <b-col md="3" cols="6" class="mb-2 mb-md-0 mb-xl-0">
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar
                    size="48"
                    :variant="
                      item.txn_status_id
                        ? resolveStatus(item.txn_status_id)
                        : ''
                    "
                  >
                    <feather-icon size="24" icon="DollarSignIcon" />
                  </b-avatar>
                </b-media-aside>
                <div
                  class="my-auto"
                  style="padding-right: 16px,width: 500px"
                >
                  <h4 class="font-weight-bolder mb-0">
                    <span style="">
                      {{ numberFormat(item.amount / 1000) }}/{{ item.quantityTransaction }}
                    </span>
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ item.txn_status_name }}
                  </b-card-text>
                </div>
                <!-- <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    {{ item.quantityTransaction }}
                  </h4>
                  <b-card-text
                    class="font-small-3 mb-0"
                    style="white-space: nowrap"
                  >
                    <span> Quantity Transaction </span>
                  </b-card-text>
                </b-media-body> -->
              </b-media>
            </b-col>
          </div>
        </div>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BOverlay,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import { numberFormat } from "@core/utils/filter";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import topDepositStoreModule from "@/views/campaign/risk-management/topDepositStoreModule";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BOverlay,
  },
  data() {
    return {
      dataAnalytic: [],
      currentPage: 1,
      fromdate: null,
      todate: null,
      total: 0,
      totalTransaction: 0,
      totalSuccess: 0,
      totalTransactionSuccess: 0,
      totalPending: 0,
      totalTransactionPending: 0,
      totalFail: 0,
      totalTransactionFail: 0,
      show: false,
    };
  },
  props: {
    fromdateFilter: {
      type: [String, null],
      default: null,
    },
    todateFilter: {
      type: [String, null],
      default: null,
    },
    refreshTopDepositAnalytic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentDateTime() {
      let dateTime = new Date().toJSON().slice(0, 19);
      return dateTime; // 2022-06-17T11:06:50.369Z
    },
    currentDate() {
      let date = new Date().toJSON().slice(0, 10);
      return {
        fromdate: date + "T00:00:00",
        todate: date + "T23:59:59",
      };
    },
    dayOfWeek() {
      var curr = new Date(); // get current date
      var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
      var last = first + 6; // last day is the first day + 6

      var firstday = new Date(curr.setDate(first)).toJSON().slice(0, 10);
      var lastday = new Date(curr.setDate(last)).toJSON().slice(0, 10);

      return (
        "Ngày Đầu tuần: " +
        firstday +
        "T00:00:00" +
        " - Ngày cuối tuần: " +
        lastday +
        "T23:59:59"
      );
    },
    dayOfMonth() {
      const date = new Date();
      const currentYear = date.getFullYear();
      const currentMonth = date.getMonth() + 1; // 👈️ months are 0-based

      const daysInCurrentMonth = this.getDaysInMonth(currentYear, currentMonth);

      for (var i = 1; i <= daysInCurrentMonth; i++) {
      }
    },
  },
  watch: {
    async fromdateFilter(newVal) {
      this.show = true;
      if (newVal) {
        this.fromdate = newVal;
      } else {
        this.fromdate = this.currentDate.fromdate;
      }
      await setTimeout(() => {
        this.fetchTopDeposits(this.fromdate, this.todate);
      }, 2000);
      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    async todateFilter(newVal) {
      this.show = true;
      if (newVal) {
        this.todate = newVal;
      } else {
        this.todate = this.currentDate.todate;
      }
      await setTimeout(() => {
        this.fetchTopDeposits(this.fromdate, this.todate);
      }, 2000);
      setTimeout(() => {
        this.show = false;
      }, 2000);
    },
    async refreshTopDepositAnalytic(newVal) {
      if (newVal) {
        this.show = true;
        await setTimeout(() => {
          this.fetchTopDeposits(
            this.fromdate || this.currentDate.fromdate,
            this.todate || this.currentDate.todate
          );
        }, 2000);
        setTimeout(() => {
          this.show = false;
        }, 2000);
        this.$emit("update:refreshTopDepositAnalytic", false);
      }
    },
  },
  mounted() {
    this.fetchTopDeposits(this.currentDate.fromdate, this.currentDate.todate);
  },
  methods: {
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate();
    },
    async fetchTopDeposits(fromdate, todate) {
      await store
        .dispatch(
          "campaign-risk-management-top-deposits/fetchDepositsAnalyticToday",
          {
            // page: this.currentPage,
            // fromdate: fromdate,
            // todate: todate,
          }
        )
        .then((response) => {
          const result = response.data;
          if (result.code == "00") {
            this.dataAnalytic = result.data;
            // this.total = result.data.totalAmount / 1000
            // this.totalTransaction = result.data.totalQuantityTransaction
            // this.totalSuccess = result.data.totalSuccess / 1000
            // this.totalTransactionSuccess = result.data.totalTransactionSuccess
            // this.totalPending = result.data.totalPending / 1000
            // this.totalTransactionPending = result.data.totalTransactionPending
            // this.totalFail = result.data.totalFail / 1000
            // this.totalTransactionFail = result.data.totalTransactionFail
          }
        });
    },
  },
  setup() {
    if (!store.hasModule("campaign-risk-management-top-deposits"))
      store.registerModule(
        "campaign-risk-management-top-deposits",
        topDepositStoreModule
      );
    onUnmounted(() => {
      // if (store.hasModule('campaign-risk-management-top-deposits')) store.unregisterModule('campaign-risk-management-top-deposits')
    });
    // const chanceColor= (data)=>{

    // }
    const resolveStatus = (stat) => {
      if (stat === 1) return "warning";
      if (stat === 2) return "primary";
      if (stat === 3) return "danger";
      if (stat === 4) return "success";
    };

    return {
      numberFormat,
      resolveStatus,
    };
  },
};
</script>
